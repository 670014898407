import React, { CSSProperties } from 'react';

import { ReactComponent as OverviewIcon } from 'assets/images/tabler-icon/dashboard.svg';
import { ReactComponent as ReportsIcon } from 'assets/images/tabler-icon/fire-analytics.svg';
import { ReactComponent as JobsIcon } from 'assets/images/tabler-icon/list.svg';
import { ReactComponent as LocationIcon } from 'assets/images/tabler-icon/location.svg';
import { ReactComponent as AssetIcon } from 'assets/images/tabler-icon/motorbike.svg';
import { ReactComponent as PartsIcon } from 'assets/images/tabler-icon/package.svg';
import { ReactComponent as UsersIcon } from 'assets/images/tabler-icon/users.svg';
import { ModulesType } from 'services/settings-service/settings.modal';

import ROUTES from './routes';

export interface ISideNavLink {
  label: string;
  path: string;
  child?: ISideNavLink[];
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  module?: ModulesType;
  sx?: CSSProperties;
}

export const DRAWER_WIDTH = 260;

export const SIDE_NAV_LINKS: ISideNavLink[] = [
  {
    label: 'side_nav.overview',
    path: ROUTES.USER.OVERVIEW,
    icon: OverviewIcon,
  },
  {
    label: 'side_nav.assets',
    path: ROUTES.USER.ASSETS.BASE_PATH,
    icon: AssetIcon,
    module: ModulesType.assets,
  },
  {
    label: 'side_nav.jobs',
    path: ROUTES.USER.JOBS.BASE_PATH,
    icon: JobsIcon,
    module: ModulesType.jobs,
  },
  {
    label: 'side_nav.locations',
    path: ROUTES.USER.SETTINGS.LOCATIONS,
    icon: LocationIcon,
    module: ModulesType.organisation_locations,
  },
  {
    label: 'users',
    path: ROUTES.USER.SETTINGS.BASE_PATH + ROUTES.USER.SETTINGS.USERS,
    icon: UsersIcon,
    module: ModulesType.users,
  },
  {
    label: 'part_s',
    path: ROUTES.USER.PARTS.BASE_PATH,
    icon: PartsIcon,
    module: ModulesType.users,
  },
  {
    label: 'side_nav.reports',
    path: ROUTES.USER.REPORTS,
    icon: ReportsIcon,
  },
];
