import { FieldError } from 'react-hook-form';

export interface IListMetaDetails {
  count: number | undefined;
  page: number;
}

export interface IListApiResponse<T> {
  meta: IListMetaDetails;
  data: T;
}

export interface ICommonApiResponse<T> {
  meta: IListMetaDetails;
  data: T;
}

export interface ICustomMeta extends IListMetaDetails {
  totalRow?: number | undefined;
  customPaginationHandler?: (page: string) => void;
  customCurrentPage?: string;
}

export type IErrorObject<T> = {
  [K in keyof T]?: FieldError | undefined | null;
};

export interface IAdditionFieldsPayload {
  additionalFields?: {
    [k: string]: string;
  };
}

export type EnumKeysNames<T extends string, K> = Partial<Record<T, K>>;

export enum ILaunchDarkModules {
  AdditionalFields = 'AdditionalFields',
  TableFilter = 'TableFilter',
  GlobalSearch = 'GlobalSearch',
  AssetConfig = 'AssetConfig',
  QRScanner = 'QRScanner',
  Reports = 'Reports',
}

export enum IEnvList {
  dev = 'dev.armada.ridezoomo.com',
  demo = 'demo.armada.ridezoomo.com',
  staging = 'staging.armada.ridezoomo.com',
  prod = 'armada.ridezoomo.com',
  amazon = 'amazon.armada.ridezoomo.com',
  localhost = 'localhost',
}

export enum IGlobalSearchModule {
  assets = 'assets',
  jobs = 'jobs',
  users = 'users',
  locations = 'locations',
  organisations = 'organisations',
  organisation_locations = 'organisation_locations',
  location_groups = 'location_groups',
  parts = 'parts',
}
