import { APIS } from '../../constants/api.constants';
import { BaseApi } from '../common/base-api';
import { processResponse } from '../common/common';
import { IFeatureModel } from './features.modal';

export interface FeaturesResponse {
  data: IFeatureModel;
}

const getFeatures = async (): Promise<Error | FeaturesResponse> => {
  const response = await BaseApi.get(APIS.FEATURES.GET_FEATURES);
  return processResponse<FeaturesResponse>(response);
};

export { getFeatures };
