import { makeStyles } from 'tss-react/mui';

const usePageLoaderStyles = makeStyles()(() => ({
  root: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default usePageLoaderStyles;
