import styled from '@emotion/styled';
import { makeStyles } from 'tss-react/mui';

import { remCalc } from 'theme/utils';

export const Spinner = styled('div')({
  '@keyframes pulsate': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  animation: 'pulsate 1s infinite ease',
  height: '20px',
  width: '20px',
});

export const useStyle = makeStyles()(() => ({
  '@keyframes fadeIn2': {
    '0%': {
      transform: 'rotate(360deg)',
    },
    '100%': {
      transform: 'rotate(0deg)',
    },
  },

  root: {
    width: '500px',
  },
  topContent: {
    fontSize: remCalc(12),
    fontWeight: 500,
    color: '#737373',
    lineHeight: '16px',
    padding: '12px',
    cursor: 'pointer',
    '&:hover': {
      background: '#F4FBFF',
    },
  },
  divider: {
    height: '1px',
    background: '#E1E3E5',
    width: '100%',
    margin: '12px 0',
  },
  title: {
    background: '#F5F5F5',
    height: '24px',
    fontSize: remCalc(12),
    textTransform: 'uppercase',
    fontWeight: 500,
    width: '100%',
    margin: '0',
    color: '#737373',
    paddingLeft: '10px',
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      textTransform: 'none',
      marginLeft: '5px',
    },
  },
  resultContent: {
    padding: '10px 16px',
    display: 'flex',
    alignItems: 'center',
    color: '#D6D6D6',
    '& > p': {
      fontSize: remCalc(12),
    },
    cursor: 'pointer',
    '&:hover': {
      background: '#F4FBFF',
    },
  },
  loaderContent: {
    padding: '10px 16px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    color: '#D6D6D6',
    '& > p': {
      fontSize: remCalc(12),
    },
  },
  moduleData: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '9px',

    '& > p': {
      fontSize: remCalc(12),
      color: '#000',
      fontWeight: 500,
    },
  },
  roundIcon: {
    width: '4px',
    height: '4px',
    background: '#000',
    borderRadius: '50%',
  },
  loaderIcon: {
    // animation: '$fadeIn2 2s linear infinite',
    keyframes: [
      {
        from: { transform: 'rotate(360deg)' },
        to: { transform: 'rotate(0deg)' },
      },
    ],
    duration: '5s',
    timingFunction: 'infinite',
  },
}));
