import React from 'react';

import { Stack, Typography } from '@mui/material';
import { Command } from 'cmdk';

import SVGIcon from 'common/components/svg-icon/SVGIcon';
import { IGlobalSearchModule } from 'services/common/common.modal';
import { remCalc } from 'theme/utils';

import { defaultSearchConfig } from './config';

interface SearchDefaultViewProps {
  onModuleSelect: (module: IGlobalSearchModule) => void;
  search: string;
}

function SearchDefaultView({ onModuleSelect, search }: SearchDefaultViewProps): JSX.Element {
  return (
    <Command.Group heading='Search...'>
      {defaultSearchConfig.map(({ id, icon, label }) => (
        <Command.Item
          key={id}
          keywords={[label]}
          onSelect={(): void => {
            onModuleSelect(id);
            (document.getElementsByClassName('search-input')[0] as HTMLInputElement)?.focus();
          }}
          style={{
            padding: '8px 16px',
            height: '40px',
          }}
        >
          <Stack direction='row' gap='8px'>
            {search && (
              <Typography
                sx={{
                  fontSize: remCalc(14),
                  lineHeight: '20px',
                }}
              >
                Search &quot;<b>{search}</b>&quot; in
              </Typography>
            )}
            <SVGIcon
              icon={icon}
              style={{
                height: '20px',
                width: '20px',
              }}
              stroke='#141414'
            />

            <Typography
              sx={{
                fontSize: remCalc(14),
                lineHeight: '20px',
              }}
            >
              {label}
            </Typography>
          </Stack>
        </Command.Item>
      ))}
    </Command.Group>
  );
}

export default SearchDefaultView;
