import { FeaturesResponse } from 'services/features-service/features.service';
import { useAppSelector } from 'store/hooks';
import { selectFeatures } from 'store/reducers/featuresSlice';

interface IUseFeaturesReturnType {
  data: FeaturesResponse | null;
}

export const useFeatures = (): IUseFeaturesReturnType => {
  const featuresData = useAppSelector(selectFeatures);

  const data = featuresData ?? null;

  return { data };
};
