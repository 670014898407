/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useMemo, useState, useEffect } from 'react';

import { Box, Popper, Grow, Paper, ClickAwayListener, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Command } from 'cmdk';
import { useNavigate, useLocation } from 'react-router-dom';

import { ReactComponent as Close } from 'assets/images/tabler-icon/close.svg';
import SVGIcon from 'common/components/svg-icon/SVGIcon';
import { APIQueryConstant } from 'constants/api.constants';
import useLaunchDark from 'hooks/useLaunchDark';
import { IGlobalSearchModule, ILaunchDarkModules } from 'services/common/common.modal';
import { remCalc } from 'theme/utils';
import { getURLParamByName } from 'utils/commonUtils';

import { getModuleLabel, IModuleConfig, modulesConfig } from './config';
import Content from './Content';
import SearchDefaultView from './DefaultView';
import RecentlyViewed from './RecentlyViewed';
import { useStyle } from './style';

import './style/vercel.css';

function SearchBar(): JSX.Element | null {
  const { classes } = useStyle();

  const { permissions } = useLaunchDark([ILaunchDarkModules.GlobalSearch]);
  const theme = useTheme();
  const [search, updateSearch] = useState('');
  const [open, setOpen] = React.useState(false);

  const [module, selectedModule] = useState<IGlobalSearchModule | null>(null);
  const id = 'simple-popover34';
  const { pathname, search: urlSearchQuery } = useLocation();
  const navigate = useNavigate();
  const mobileMatches = useMediaQuery(theme.breakpoints.between('xs', 'md'));
  const [ref, setRef] = useState<HTMLDivElement>();

  const currentModule = useMemo((): IModuleConfig | undefined => {
    return modulesConfig.find(({ listRoute }) => pathname?.indexOf(listRoute) !== -1);
  }, [pathname]);

  useEffect(() => {
    selectedModule((currentModule?.module as IGlobalSearchModule) ?? null);
  }, [currentModule?.module, selectedModule]);

  useEffect(() => {
    const openEvenListener = (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        if (open) {
          setOpen(false);
        }
        (document.getElementsByClassName('search-input')[0] as HTMLInputElement)?.blur();
      }

      // @ts-ignore
      if (event.key === '/' && event.target?.tagName.toUpperCase() !== 'INPUT') {
        if (!open) {
          setOpen(true);
          setTimeout(() => {
            (document.getElementsByClassName('search-input')[0] as HTMLInputElement)?.focus();
          }, 0);
        }
      }
    };
    window.addEventListener('keydown', openEvenListener);
    return () => {
      window.removeEventListener('keydown', openEvenListener);
    };
  }, [open]);

  useEffect(() => {
    const q = getURLParamByName(APIQueryConstant.searchQuery);

    updateSearch(q ?? '');
  }, [updateSearch, pathname]);

  const resetSearch = (query?: string): void => {
    setOpen(false);
    updateSearch(query ?? '');
  };

  const closeSearch = (): void => {
    setOpen(false);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const getPopoverWidth = useMemo((): string | number | undefined => {
    if (mobileMatches) {
      return ref?.clientWidth ?? '300px';
    }

    return '500px';
  }, [mobileMatches, ref]);

  if (!permissions[ILaunchDarkModules.GlobalSearch]) {
    return null;
  }

  const getComponent = (): JSX.Element | null => {
    const data = modulesConfig.find((d) => d.module === module);
    if (!module || !data) {
      return null;
    }

    return (
      <Content {...data} search={search} resetSearch={resetSearch} closeSearch={closeSearch} />
    );
  };

  return (
    <>
      <Box
        className={`global-search-bar ${classes.root}`}
        ref={(newRef): void => setRef(newRef as HTMLDivElement)}
        aria-describedby={id}
        id='global-search-bar'
        sx={{ margin: mobileMatches ? '0 8px' : 0, height: '50px' }}
      />
      <Popper
        id={id}
        open
        anchorEl={ref ?? null}
        role={undefined}
        placement='bottom-start'
        transition
        disablePortal
        sx={{
          top: '-43px !important',
          zIndex: 9,
        }}
      >
        {({ TransitionProps, placement }): JSX.Element => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper
              sx={{
                width: getPopoverWidth,
                borderRadius: '0px',
                border: 'none',
                padding: '0',
                overflow: 'hidden',
                background: 'transparent',
                boxShadow:
                  '0px 0px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <Box className={`vercel ${!open ? ' empty-input' : ''}`}>
                  <Command shouldFilter={!module}>
                    <Stack
                      direction='row'
                      alignItems='center'
                      sx={{ background: '#fff', borderRadius: '4px', overflow: 'hidden' }}
                    >
                      {module && (
                        <Box
                          sx={{ m: '8px', mr: '0', pr: '8px', borderRight: '1px solid #E5E5E5' }}
                        >
                          <Stack
                            direction='row'
                            alignItems='center'
                            sx={{
                              height: '24px',
                              background: '#E5E5E5',
                              borderRadius: '4px',
                              px: '6px',
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: '700',
                                color: '#141414',
                                fontSize: '12px',
                                '&:first-letter': {
                                  textTransform: 'capitalize',
                                },
                              }}
                            >
                              {getModuleLabel(module)}
                            </Typography>

                            <SVGIcon
                              icon={Close}
                              stroke='#737373'
                              width='20'
                              height='20'
                              style={{
                                cursor: 'pointer',
                                marginLeft: '4px',
                              }}
                              onClick={(): void => {
                                selectedModule(null);
                                updateSearch('');
                              }}
                            />
                          </Stack>
                        </Box>
                      )}
                      <Command.Input
                        value={search}
                        onValueChange={(searchString: string): void => {
                          updateSearch(searchString);
                          if (!open) {
                            setOpen(true);
                          }
                        }}
                        placeholder='Search'
                        className='search-input'
                        onFocus={(): void => {
                          setOpen(true);
                        }}
                        onKeyDown={(e): void => {
                          if (e.key === 'Backspace' || e.keyCode === 8) {
                            if (!search) {
                              selectedModule(null);
                              if (!open) {
                                setOpen(true);
                              }
                            }
                          }
                        }}
                      />
                      {!open && !urlSearchQuery && !search ? (
                        <Box
                          sx={{
                            width: '24px',
                            height: '24px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '4px',
                            border: '1px solid #D6D6D6',
                            background: '#FAFAFA',
                            mr: '8px',
                            color: '#737373',
                            flexShrink: 0,
                          }}
                        >
                          /
                        </Box>
                      ) : (
                        <SVGIcon
                          icon={Close}
                          stroke='#737373'
                          width='20'
                          height='20'
                          style={{
                            marginRight: '8px',
                            cursor: 'pointer',
                          }}
                          onClick={(): void => {
                            if (urlSearchQuery) {
                              navigate(pathname);
                            }
                            updateSearch('');
                          }}
                        />
                      )}
                    </Stack>
                    <Command.List id='default'>
                      {open && !module && (
                        <SearchDefaultView onModuleSelect={selectedModule} search={search} />
                      )}
                      {open && !module && (
                        <RecentlyViewed
                          selectedModule={module}
                          onModuleSelect={selectedModule}
                          resetSearch={resetSearch}
                          closeSearch={closeSearch}
                        />
                      )}
                      {open && module && !search && (
                        <RecentlyViewed
                          selectedModule={module}
                          onModuleSelect={selectedModule}
                          resetSearch={resetSearch}
                          closeSearch={closeSearch}
                        />
                      )}
                      {module && search && open && getComponent()}
                    </Command.List>
                    {mobileMatches && open && (
                      <Stack
                        sx={{
                          background: '#fff',
                          p: '16px',
                        }}
                      >
                        <Box
                          sx={{
                            height: '40px',
                            p: '4px 16px',
                            borderRadius: '4px',
                            border: '1px solid #D6D6D6',
                            ml: 'auto',
                            fontSize: remCalc(14),
                            fontWeight: 600,
                            boxShadow:
                              '0px 0px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
                            color: '#737373',
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                          onClick={(): void => {
                            setOpen(false);
                          }}
                        >
                          <SVGIcon
                            icon={Close}
                            stroke='#737373'
                            width='20'
                            height='20'
                            style={{
                              marginRight: '8px',
                              cursor: 'pointer',
                            }}
                          />
                          Close
                        </Box>
                      </Stack>
                    )}
                  </Command>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default SearchBar;
