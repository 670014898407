/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FeaturesResponse } from 'services/features-service/features.service';

import { RootState } from '../store';

type FeaturesDetails = FeaturesResponse | null;

interface FeaturesSliceState {
  details: FeaturesDetails;
}

const initialState: FeaturesSliceState = {
  details: null,
};

export const featuresSlice = createSlice({
  name: 'features',
  initialState,
  reducers: {
    setFeatures: (state, action: PayloadAction<FeaturesDetails>) => {
      state.details = action.payload;
    },
  },
});

export const { setFeatures } = featuresSlice.actions;

export const selectFeatures = (state: RootState): FeaturesDetails => state.features.details;

export default featuresSlice.reducer;
