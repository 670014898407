import { useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import ROUTES from 'constants/routes';
import { useAppSelector } from 'store/hooks';
import { getCurrentLoggedInUserDetails } from 'store/reducers/userSlice';

function NewUserNavigation(): null {
  const userDetails = useAppSelector(getCurrentLoggedInUserDetails);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isProfilePath = [ROUTES.USER.PROFILE_COMPLETE, ROUTES.USER.PROFILE_DETAILS].includes(
    pathname,
  );
  useEffect(() => {
    if (
      userDetails &&
      userDetails?.linkedOrganisations?.length === 0 &&
      userDetails?.invites?.length > 0 &&
      !isProfilePath
    ) {
      if (!userDetails.name) {
        navigate(ROUTES.USER.PROFILE_COMPLETE);
      } else {
        navigate(ROUTES.USER.PROFILE_DETAILS);
      }
    }
  }, [userDetails, navigate, isProfilePath]);
  return null;
}

export default NewUserNavigation;
