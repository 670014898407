import { ReactComponent as JobsIcon } from 'assets/images/tabler-icon/list.svg';
import { ReactComponent as LocationIcon } from 'assets/images/tabler-icon/location.svg';
import { ReactComponent as AssetIcon } from 'assets/images/tabler-icon/motorbike.svg';
import { ReactComponent as PartsIcon } from 'assets/images/tabler-icon/package.svg';
import { ReactComponent as UsersIcon } from 'assets/images/tabler-icon/users.svg';
import ROUTES from 'constants/routes';
import { IGlobalSearchModule } from 'services/common/common.modal';

export interface IModuleConfig {
  module: string;
  api: string;
  listRoute: string;
  detailsRoute?: string;
  mobileView?: boolean;
}

export const modulesConfig: IModuleConfig[] = [
  {
    module: IGlobalSearchModule.assets,
    api: 'ASSETS.ASSET_LIST',
    listRoute: ROUTES.USER.ASSETS.BASE_PATH,
    detailsRoute: ROUTES.USER.ASSETS.ASSET_DETAILS,
  },
  {
    module: IGlobalSearchModule.jobs,
    api: 'JOBS.GET_JOBS',
    listRoute: ROUTES.USER.JOBS.BASE_PATH,
    detailsRoute: ROUTES.USER.JOBS.JOB_DETAILS,
  },

  {
    module: IGlobalSearchModule.users,
    api: 'SETTINGS.USERS_LIST',
    listRoute: ROUTES.USER.SETTINGS.BASE_PATH + ROUTES.USER.SETTINGS.USERS,
    detailsRoute: ROUTES.USER.SETTINGS.BASE_PATH + ROUTES.USER.SETTINGS.USER_DETAILS,
  },
  {
    module: IGlobalSearchModule.parts,
    api: 'PARTS.PARTS_LIST',
    listRoute: ROUTES.USER.PARTS.BASE_PATH,
    detailsRoute: ROUTES.USER.PARTS.PARTS_DETAILS,
  },
  {
    module: IGlobalSearchModule.organisation_locations,
    api: 'SETTINGS.GET_ORG_LOCATION',
    listRoute: ROUTES.USER.SETTINGS.LOCATIONS,
    detailsRoute: ROUTES.USER.SETTINGS.LOCATION_DETAILS,
    mobileView: false,
  },
  {
    module: IGlobalSearchModule.location_groups,
    api: 'SETTINGS.GET_GROUPS',
    listRoute: ROUTES.USER.SETTINGS.BASE_PATH + ROUTES.USER.SETTINGS.GROUPS_DETAILS,
    detailsRoute: ROUTES.USER.SETTINGS.BASE_PATH + ROUTES.USER.SETTINGS.GROUPS_DETAILS,
    mobileView: false,
  },
];

export const defaultSearchConfig = [
  {
    id: IGlobalSearchModule.assets,
    label: 'Assets',
    icon: AssetIcon,
  },
  {
    id: IGlobalSearchModule.jobs,
    label: 'Jobs',
    icon: JobsIcon,
  },
  {
    id: IGlobalSearchModule.organisation_locations,
    label: 'Locations',
    icon: LocationIcon,
  },
  {
    id: IGlobalSearchModule.parts,
    label: 'Parts',
    icon: PartsIcon,
  },
  {
    id: IGlobalSearchModule.users,
    label: 'Users',
    icon: UsersIcon,
  },
];

export const getModuleLabel = (module: IGlobalSearchModule): string => {
  return defaultSearchConfig.find((d) => d.id === module)?.label ?? '-';
};
