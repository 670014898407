import React, { useCallback, useEffect } from 'react';

import { Box, Typography, debounce } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Command } from 'cmdk';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowRight } from 'assets/images/tabler-icon/arrow-right.svg';
import { ReactComponent as Sad } from 'assets/images/tabler-icon/sad.svg';
import SVGIcon from 'common/components/svg-icon/SVGIcon';
import { APIQueryConstant } from 'constants/api.constants';
import { IRefetchActionType } from 'hooks/types';
import useAPI from 'hooks/useAPI';
import { IAssetListDetails } from 'services/asset-service/asset.modal';
import { IGlobalSearchModule, IListApiResponse } from 'services/common/common.modal';
import { JobDetails } from 'services/job-service/job.modal';
import { IPartsList } from 'services/parts/parts.modal';
import {
  IUserDetailsResponse,
  ILocationDetails,
  ILocationGroupDetails,
} from 'services/settings-service/settings.modal';
import { remCalc } from 'theme/utils';
import { addUrlId } from 'utils/commonUtils';

import { getModuleLabel, IModuleConfig } from './config';
import ModuleListView from './ModuleListView';
import { useStyle } from './style';
import { useRecentlyViewed } from './useRecentlyViewed';

export interface IContentProps extends IModuleConfig {
  search: string;
  resetSearch: () => void;
  closeSearch: () => void;
}

function Content({
  module,
  api,
  search,
  listRoute,
  detailsRoute,
  resetSearch,
  closeSearch,
}: IContentProps): JSX.Element | null {
  const { classes } = useStyle();
  const { t } = useTranslation(['translation', 'settings']);
  const navigate = useNavigate();
  const { setRecentlyViewedData } = useRecentlyViewed();
  const { response, error, refetchApi, loading } = useAPI<
    IListApiResponse<Record<string, unknown>[]>
  >(api, {
    isDisabled: true,
  });

  const handleRedirection = (id: string, data: unknown, m: IGlobalSearchModule): void => {
    setRecentlyViewedData(data, m);
    closeSearch();
    resetSearch();
    if (detailsRoute) {
      (document.getElementsByClassName('search-input')[0] as HTMLInputElement)?.blur();
      navigate(addUrlId(detailsRoute, id));
    }
  };

  const getMoreResult = (m: IGlobalSearchModule): JSX.Element | null => {
    return (
      <Command.Item
        value={listRoute}
        onSelect={(): void => {
          closeSearch();
          setRecentlyViewedData({ showAllQuery: search }, m);
          (document.getElementsByClassName('search-input')[0] as HTMLInputElement)?.blur();
          navigate(`${listRoute}?${APIQueryConstant.searchQuery}=${encodeURIComponent(search)}`);
        }}
        style={{
          padding: '16px',
        }}
      >
        <Typography
          sx={{
            fontSize: remCalc(14),
            color: '#0A78C1',
            fontWeight: 400,
            cursor: 'pointer',
            lineHeight: '20px',
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          {t('global_search.view_all_results')}
          <SVGIcon height='16' width='16' icon={ArrowRight} />
        </Typography>
      </Command.Item>
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getAPI = useCallback(
    debounce((searchQuery: string): void => {
      if (refetchApi) {
        const query = {
          [APIQueryConstant.searchQuery]: searchQuery,
          page: 1,
          size: 5,
          filter: '',
          sortField: '',
          sortOrder: '',
        };
        refetchApi(IRefetchActionType.create, query);
      }
    }, 500),
    [],
  );

  useEffect(() => {
    if (search) {
      getAPI(search);
    }
  }, [getAPI, search]);

  if (error && !loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          p: '16px',
          fontSize: remCalc(12),
          color: '#525252',
          flexDirection: 'column',
        }}
      >
        <SVGIcon height='20' width='20' stroke='#DF2D2D' icon={Sad} />
        Something went wrong
      </Box>
    );
  }

  if (!loading && !response?.data.length) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          p: '16px',
          fontSize: remCalc(12),
          color: '#525252',
          flexDirection: 'column',
        }}
      >
        <SVGIcon height='20' width='20' stroke='#DF2D2D' icon={Sad} />
        Your keyword “{search}” rendered no result.
      </Box>
    );
  }

  const getContent = (): JSX.Element | JSX.Element[] | null => {
    switch (module) {
      case IGlobalSearchModule.assets: {
        const { data = [] } = (response as unknown as IListApiResponse<IAssetListDetails[]>) || {};

        return (
          <>
            {data?.map((d) => {
              return (
                <ModuleListView
                  key={d.assetId}
                  handleRedirection={handleRedirection}
                  details={d}
                  module={module}
                />
              );
            })}
            {getMoreResult(IGlobalSearchModule.assets)}
          </>
        );
      }

      case IGlobalSearchModule.jobs: {
        const { data = [] } = (response as unknown as IListApiResponse<JobDetails[]>) || {};
        return (
          <>
            {data?.map((d) => {
              return (
                <ModuleListView
                  key={d.jobId}
                  handleRedirection={handleRedirection}
                  details={d}
                  module={module}
                />
              );
            })}
            {getMoreResult(IGlobalSearchModule.jobs)}
          </>
        );
      }

      case IGlobalSearchModule.parts: {
        const { data = [] } = (response as unknown as IListApiResponse<IPartsList[]>) || {};

        return (
          <>
            {data?.map((d) => {
              return (
                <ModuleListView
                  key={d.partId}
                  handleRedirection={handleRedirection}
                  details={d}
                  module={module}
                />
              );
            })}
            {getMoreResult(IGlobalSearchModule.parts)}
          </>
        );
      }

      case IGlobalSearchModule.organisation_locations: {
        const { data = [] } = (response as unknown as IListApiResponse<ILocationDetails[]>) || {};
        return (
          <>
            {data?.map((d) => {
              return (
                <ModuleListView
                  key={d.locationId}
                  handleRedirection={handleRedirection}
                  details={d}
                  module={module}
                />
              );
            })}
            {getMoreResult(IGlobalSearchModule.organisation_locations)}
          </>
        );
      }

      case IGlobalSearchModule.location_groups: {
        const { data = [] } =
          (response as unknown as IListApiResponse<ILocationGroupDetails[]>) || {};

        return (
          <>
            {data?.map((d) => {
              return (
                <ModuleListView
                  key={d.locationGroupId}
                  handleRedirection={handleRedirection}
                  details={d}
                  module={module}
                />
              );
            })}
            {getMoreResult(IGlobalSearchModule.location_groups)}
          </>
        );
      }

      case IGlobalSearchModule.users: {
        const { data = [] } =
          (response as unknown as IListApiResponse<IUserDetailsResponse[]>) || {};

        return (
          <>
            {data?.map((d) => {
              return (
                <ModuleListView
                  key={d.userId}
                  handleRedirection={handleRedirection}
                  details={d}
                  module={module}
                />
              );
            })}
            {getMoreResult(IGlobalSearchModule.users)}
          </>
        );
      }

      default:
        return null;
    }
  };
  const { meta, data } = (response as unknown as IListApiResponse<unknown[]>) || {};

  return (
    <>
      {loading && (
        <Command.Loading>
          <Box className={classes.loaderContent}>
            <Typography
              sx={{
                ml: '12px',
                color: '#141414',
                fontSize: remCalc(14),
                fontWeight: 600,
                mb: '16px',
                width: '100%',
              }}
            >
              Searching {getModuleLabel(module as unknown as IGlobalSearchModule)}...
            </Typography>
            <Box
              sx={{
                p: '12px',
              }}
            >
              <CircularProgress size={20} />
            </Box>
          </Box>
        </Command.Loading>
      )}
      {!loading && (
        <>
          {!!data.length && (
            <Typography
              sx={{
                fontSize: remCalc(14),
                fontWeight: '600',
                lineHeight: '20px',
                p: '16px',
              }}
            >
              {meta.count} Result Found
            </Typography>
          )}
          {getContent()}
        </>
      )}
    </>
  );
}

export default React.memo(Content);
