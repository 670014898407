import React, { CSSProperties } from 'react';

import { ReactComponent as SingleUserIcon } from 'assets/images/tabler-icon/outline-user.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/tabler-icon/settings.svg';
import ROUTES from 'constants/routes';
import { ILaunchDarkModules } from 'services/common/common.modal';
import {
  ActionType,
  IAdditionalFieldsEntity,
  IAssetConfigModule,
  ModulesType,
} from 'services/settings-service/settings.modal';
import { addUrlId, removeUUID } from 'utils/commonUtils';

export interface ISettingsSideNavLink {
  label: string;
  path: string;
  child?: ISettingsSideNavLink[];
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  module?: string;
  sx?: CSSProperties;
  darkLaunchModule?: ILaunchDarkModules;
}

export const SETTINGS_SIDE_NAV_LINKS: ISettingsSideNavLink[] = [
  {
    label: 'side_nav.account',
    path: ROUTES.USER.REPORTS,
    icon: SingleUserIcon,
    child: [
      {
        label: 'side_nav.org_details',
        path: `${ROUTES.USER.SETTINGS.BASE_PATH}${ROUTES.USER.SETTINGS.ORGANISATIONS_DETAILS}`,
      },
    ],
  },

  {
    label: 'side_nav.system_settings',
    path: ROUTES.USER.SETTINGS.BASE_PATH,
    icon: SettingsIcon,
    child: [
      {
        label: 'side_nav.locations',
        path: ROUTES.USER.SETTINGS.BASE_PATH + ROUTES.USER.SETTINGS.GROUPS,
        child: [
          {
            label: 'side_nav.groups',
            path: `${ROUTES.USER.SETTINGS.BASE_PATH + ROUTES.USER.SETTINGS.GROUPS}`,
          },
        ],
      },
      {
        label: 'side_nav.assets',
        path: ROUTES.USER.SETTINGS.BASE_PATH + ROUTES.USER.SETTINGS.ASSET_CONFIG,
        darkLaunchModule: ILaunchDarkModules.AssetConfig,
        module: `${ActionType.read}:${ModulesType.assets}`,
        child: [
          {
            label: 'side_nav.types',
            path: `${ROUTES.USER.SETTINGS.BASE_PATH + ROUTES.USER.SETTINGS.ASSET_CONFIG}/${
              IAssetConfigModule.types
            }`,
          },
          {
            label: 'side_nav.makes',
            path: `${ROUTES.USER.SETTINGS.BASE_PATH + ROUTES.USER.SETTINGS.ASSET_CONFIG}/${
              IAssetConfigModule.makes
            }`,
          },
          {
            label: 'side_nav.models',
            path: `${ROUTES.USER.SETTINGS.BASE_PATH + ROUTES.USER.SETTINGS.ASSET_CONFIG}/${
              IAssetConfigModule.models
            }`,
          },
        ],
      },

      {
        label: 'side_nav.jobs',
        path: ROUTES.USER.SETTINGS.BASE_PATH + ROUTES.USER.SETTINGS.JOB_TYPES,
        darkLaunchModule: ILaunchDarkModules.AssetConfig,
        module: `${ActionType.read}:${ModulesType.jobs}`,
        child: [
          {
            label: 'side_nav.types',
            path: `${ROUTES.USER.SETTINGS.BASE_PATH + ROUTES.USER.SETTINGS.JOB_TYPES}`,
          },
          {
            label: 'side_nav.job_activity',
            path: `${ROUTES.USER.SETTINGS.BASE_PATH + ROUTES.USER.SETTINGS.JOB_ACTIVITY}`,
          },
        ],
      },
      {
        label: 'side_nav.parts',
        path: ROUTES.USER.SETTINGS.BASE_PATH + ROUTES.USER.SETTINGS.CATEGORIES,
        darkLaunchModule: ILaunchDarkModules.AssetConfig,
        module: `${ActionType.read}:${ModulesType.parts}`,
        child: [
          {
            label: 'side_nav.categories',
            path: `${ROUTES.USER.SETTINGS.BASE_PATH + ROUTES.USER.SETTINGS.CATEGORIES}`,
          },
        ],
      },
      {
        label: 'side_nav.additional_fields',
        path: ROUTES.USER.SETTINGS.BASE_PATH + ROUTES.USER.SETTINGS.ADDITIONAL_FIELDS,
        darkLaunchModule: ILaunchDarkModules.AdditionalFields,
        module: `${ActionType.manage}:${ModulesType.settings}`,
        child: [
          {
            label: 'side_nav.asset_fields',
            path: `${ROUTES.USER.SETTINGS.BASE_PATH + ROUTES.USER.SETTINGS.ADDITIONAL_FIELDS}/${
              IAdditionalFieldsEntity.asset
            }`,
          },
          {
            label: 'side_nav.job_fields',
            path: `${ROUTES.USER.SETTINGS.BASE_PATH + ROUTES.USER.SETTINGS.ADDITIONAL_FIELDS}/${
              IAdditionalFieldsEntity.job
            }`,
          },
          {
            label: 'side_nav.organisation_location_fields',
            path: `${ROUTES.USER.SETTINGS.BASE_PATH + ROUTES.USER.SETTINGS.ADDITIONAL_FIELDS}/${
              IAdditionalFieldsEntity.organisation_location
            }`,
          },
          {
            label: 'side_nav.user_fields',
            path: `${ROUTES.USER.SETTINGS.BASE_PATH + ROUTES.USER.SETTINGS.ADDITIONAL_FIELDS}/${
              IAdditionalFieldsEntity.user
            }`,
          },
          {
            label: 'side_nav.parts_fields',
            path: `${ROUTES.USER.SETTINGS.BASE_PATH + ROUTES.USER.SETTINGS.ADDITIONAL_FIELDS}/${
              IAdditionalFieldsEntity.part
            }`,
          },
        ],
      },
    ],
  },
];

const settingLinks = [
  `${ROUTES.USER.SETTINGS.BASE_PATH}${ROUTES.USER.SETTINGS.ADDITIONAL_FIELDS}`,
  `${ROUTES.USER.SETTINGS.BASE_PATH}${ROUTES.USER.SETTINGS.ASSET_CONFIG}`,
  `${ROUTES.USER.SETTINGS.BASE_PATH + ROUTES.USER.SETTINGS.JOB_TYPES}`,
  `${ROUTES.USER.SETTINGS.BASE_PATH + ROUTES.USER.SETTINGS.GROUPS}`,
  `${ROUTES.USER.SETTINGS.BASE_PATH}${ROUTES.USER.SETTINGS.ORGANISATIONS_DETAILS}`,
  `${ROUTES.USER.SETTINGS.BASE_PATH + ROUTES.USER.SETTINGS.CATEGORIES}`,
  `${ROUTES.USER.SETTINGS.BASE_PATH + ROUTES.USER.SETTINGS.ADD_JOB_ACTIVITY}`,
  `${ROUTES.USER.SETTINGS.BASE_PATH + ROUTES.USER.SETTINGS.JOB_ACTIVITY}`,
  `${ROUTES.USER.SETTINGS.BASE_PATH + addUrlId(ROUTES.USER.SETTINGS.EDIT_JOB_ACTIVITY, '')}`,
];

export const isSettingEnable = (path: string): boolean =>
  settingLinks.some((p) => removeUUID(path).includes(p));
