import React from 'react';

import { Stack, Typography } from '@mui/material';

interface SearchRowProps {
  children: string | JSX.Element | JSX.Element[] | null;
  desc: string | JSX.Element | undefined;
}

function SearchRow({ children, desc }: SearchRowProps): JSX.Element {
  return (
    <Stack gap='4px'>
      <Stack
        gap='8px'
        direction='row'
        alignItems='center'
        sx={{
          '& > p': {
            color: '#141414',
            lineHeight: '20px',
            fontWeight: 400,
            fontSize: '14px',
            '&:first-letter': {
              textTransform: 'capitalize',
            },
          },
        }}
      >
        {children}
      </Stack>
      <Typography sx={{ fontSize: '12px', color: '#A3A3A3', lineHeight: '16px' }}>
        {desc ?? '-'}
      </Typography>
    </Stack>
  );
}

export default SearchRow;
