import { ILaunchDarkModules, IEnvList, EnumKeysNames } from 'services/common/common.modal';

const config: EnumKeysNames<Partial<IEnvList>, ILaunchDarkModules[]> = {
  [IEnvList.dev]: [
    ILaunchDarkModules.AdditionalFields,
    ILaunchDarkModules.TableFilter,
    ILaunchDarkModules.GlobalSearch,
    ILaunchDarkModules.AssetConfig,
    ILaunchDarkModules.QRScanner,
    ILaunchDarkModules.Reports,
  ],
  [IEnvList.demo]: [
    ILaunchDarkModules.AdditionalFields,
    ILaunchDarkModules.TableFilter,
    ILaunchDarkModules.GlobalSearch,
    ILaunchDarkModules.AssetConfig,
    ILaunchDarkModules.QRScanner,
    ILaunchDarkModules.Reports,
  ],
  [IEnvList.staging]: [
    ILaunchDarkModules.AdditionalFields,
    ILaunchDarkModules.TableFilter,
    ILaunchDarkModules.GlobalSearch,
    ILaunchDarkModules.AssetConfig,
    ILaunchDarkModules.QRScanner,
    ILaunchDarkModules.Reports,
  ],
  [IEnvList.amazon]: [
    ILaunchDarkModules.GlobalSearch,
    ILaunchDarkModules.AssetConfig,
    ILaunchDarkModules.AdditionalFields,
  ],
  [IEnvList.prod]: [
    ILaunchDarkModules.GlobalSearch,
    ILaunchDarkModules.AssetConfig,
    ILaunchDarkModules.AdditionalFields,
    ILaunchDarkModules.TableFilter,
    ILaunchDarkModules.QRScanner,
  ],
};

export type ILaunchDarkPermission = EnumKeysNames<ILaunchDarkModules, boolean>;

interface IUseLaunchDarkReturnType {
  permissions: ILaunchDarkPermission;
  configMap: ILaunchDarkModules[];
  getModulePermission: (module: ILaunchDarkModules | undefined) => boolean;
}

const useLaunchDark = (modules: (ILaunchDarkModules | undefined)[]): IUseLaunchDarkReturnType => {
  const isLocalhost = window.location.hostname === IEnvList.localhost;

  const currentConfig = config[window.location.hostname as unknown as IEnvList] ?? [];

  const getModulePermission = (m: ILaunchDarkModules | undefined): boolean => {
    if (!m || isLocalhost) {
      return true;
    }
    return currentConfig.includes(m);
  };

  const getPermissions = (): ILaunchDarkPermission => {
    return modules.reduce((acc: ILaunchDarkPermission, v) => {
      if (!v) {
        return acc;
      }
      acc[v] = getModulePermission(v);
      return acc;
    }, {});
  };

  return { permissions: getPermissions(), configMap: currentConfig, getModulePermission };
};

export default useLaunchDark;
